/*
 * Navigation Site
 */
export default [
  {
    to: '/',
    title: 'Accueil',
  },
  {
    to: '/metier-developpeur',
    title: 'Le Métier de Développeur',
  },
  {
    to: '/initiation',
    title: 'Les mains dans le code',
  },
  // {
  //   to: '/formations',
  //   title: 'Formations',
  // },
  {
    to: '/blog',
    title: 'Parlons Dev',
  },
];
