export default [
  {
    to: '/about',
    label: 'C’est quoi DeviensDev',
  },
  {
    to: '/contribuer',
    label: 'Comment contribuer',
  },
];
